import Chart from "react-apexcharts";
import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { DateRangeDonuts } from "../DateRange/DateRangeDonuts";
import moment from "moment";
import {
  donutsDataAPIDateAdded,
  graphDataInterface,
} from "../../API/DashboardAPI/DashboardAPIS";

type Props = {};

const DashboardDonut = (props: any) => {
  const [dataAPI, setDataAPi] = React.useState<any>(props?.data);
  const [dateSelect, setDateSelect] = React.useState<string>(
    moment().format("YYYY-MM-DD")
  );

  const onDateSelect = (e: any) => {
    // setDateSelect(e);
    setDateSelect(moment(e).format("YYYY-MM-DD"));
  };
  const dateDependedAPIFn = async () => {
    let data: graphDataInterface = await donutsDataAPIDateAdded(dateSelect);
    if (data.status === "success") {
      const donutData: any = data?.data[0];
      // console.log(data?.data[0]);
      const donutsData = [
        parseInt(donutData?.dry_solid_waste),
        parseInt(donutData?.wet_solid_waste),
        parseInt(donutData?.general),
      ];
      setDataAPi(donutsData);
    }
  };
  React.useEffect(() => {
    dateDependedAPIFn();
  }, [dateSelect]);

  const [option, setOption] = useState({
    labels: ["Dry", "Wet", "General"],
    dataLabels: {
      enabled: true,
      // formatter: function (val: any) {
      //   return val + "%";
      // },
      formatter: function (
        value: any,
        { seriesIndex, dataPointIndex, w }: any
      ) {
        return (
          parseInt(w.config.series[seriesIndex]).toFixed(0) +
          " (" +
          parseInt(value).toFixed(0) +
          "%)"
        );
      },
    },
    legend: {
      show: true,
      position: "right",
      fontSize: "16px",
      fontWeight: 600,
    },

    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      },
    },
  });
  return (
    <div>
      {!dataAPI ? (
        <div className="flex justify-center items-center h-[350px]">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className="flex items-end justify-end pr-4">
            <DateRangeDonuts onDateSelect={onDateSelect} />
          </div>
          <Chart
            //@ts-ignore

            options={option}
            series={dataAPI}
            type="donut"
            height={300}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardDonut;
