import { ObjectFlags } from "typescript";
import logo from "../assets/ww.jpg";

const Header = () => {
  return (
    <div className="Header">
      <div className="logo-text">
        <img src={logo} alt="logo" style={{ objectFit: "contain" }} />
        <p>
          NEWTOWN KOLKATA <br />
          DEVELOPMENT AUTHORITY
        </p>
      </div>
      <p>SMART SOLID WASTE MANAGEMENT</p>
    </div>
  );
};

export default Header;
