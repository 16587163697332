import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LoginCred } from "../util/loginCred";
import { login } from "../app/userSlice";
import { useNavigate } from "react-router-dom";
import { credentialsType } from "../util/loginCred";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

function Login() {
  const [Creds, setCreds] = useState<credentialsType>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [LoginFailError, setLoginFailError] = useState<string | null>(null);

  const isLoggedIn = useSelector((state: any) => state.user.loggedStatus);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/mapview");
    } else {
      navigate("/");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (Creds) {
      const data = LoginCred.find(
        (item) => item.email === Creds.email && item.password === Creds.password
      );
      if (data) {
        dispatch(login({ name: data?.name, email: data?.email }));
        console.log("Login Successful");
        setLoginFailError(null);
      } else {
        setLoginFailError("Incorrect Crendentials");
        console.log("Login Failed");
      }
    }
    return () => {
      console.log("Login Unmount");
    };
  }, [Creds]);

  const mapProps = {
    center: {
      lat: 22.562166,
      lng: 88.409355,
    },
    zoom: 8,
  };

  return (
    <div className="Login">
      <div
        style={{
          height: "100vh",
          width: "100%",
          position: "absolute",
          zIndex: -1,
        }}
      >
        <MapContainer
          style={{ height: "100vh", width: "100%" }}
          center={mapProps.center}
          zoom={mapProps.zoom}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapContainer>
      </div>
      <div className="Form">
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={(values: { email: string; password: string }) => {
            const errors: any = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.password) {
              errors.password = "Required";
            }
            return errors;
          }}
          onSubmit={(values: any, { setSubmitting }: any) => {
            setTimeout(() => {
              setCreds(values);
              setSubmitting(false);
            }, 400);
          }}
        >
          {({ isSubmitting }: any) => (
            <Form className="FormFields">
              {/* <div className="LogoName" style={{ marginBottom: 20 }}>
                <img src="logo.jpg" alt="Nkda Logo" width={50} height={50} />
                <p style={{ textAlign: "left" }}>
                  NEW TOWN KOLKATA
                  <br />
                  DEVELOPMENT AUTHORITY
                </p>
              </div> */}
              <Field
                type="email"
                name="email"
                placeholder="Email"
                className="Fields"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="ErrorMsgEmail"
              />
              <Field
                type="password"
                name="password"
                placeholder="Password"
                className="Fields"
              />
              <ErrorMessage
                name="password"
                component="div"
                className="ErrorMsgPass"
              />
              <p className="ErrorMsgPass">{LoginFailError}</p>
              <button
                type="submit"
                disabled={isSubmitting}
                className="SubmitBtn"
              >
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Login;
