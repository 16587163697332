import React, { useState } from "react";
import {
  FaWindowClose,
  FaTruck,
  FaBatteryFull,
  FaSignal,
  FaPlug,
} from "react-icons/fa";
import { MdSpeed } from "react-icons/md";
import "./components.css";
import { vehicleListType } from "../API/getVehicleList";
import moment from "moment";

export interface DetailCardCardDetail {
  CardDetail: vehicleListType;
  setCardDetails: React.Dispatch<
    React.SetStateAction<vehicleListType | undefined>
  >;
}

const DetailCard: React.FC<DetailCardCardDetail> = ({
  CardDetail,
  setCardDetails,
}) => {
  return (
    <>
      {
        <div className="DetailCard">
          <div className="close">
            <FaWindowClose color={"#fff"} onClick={() => {setCardDetails(undefined)}} />
          </div>
          <div className="DetailCard-main">
            <div>
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "5px 5px 2px 5px",
                  borderRadius: 5,
                  width: "40px",
                  height: "40px",
                }}
              >
                <FaTruck size={40} color={"#aaa"} />
              </div>{" "}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 20,
                }}
              >
                <p
                  style={{
                    margin: "0px",
                    fontWeight: "bold",
                    padding: "0px 0 0 10px",
                  }}
                >
                  {CardDetail?.vehicalNumber}
                </p>
                <p style={{ margin: "0px 0 0 10px", fontSize: ".7em" }}>
                  {moment(
                    moment(CardDetail?.lastActive).format("YYYY-MM-DD HH:mm:ss")
                  ).fromNow()}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "5px 5px 10px -2px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: "bold",
                  color: "#aaa",
                  fontSize: ".8em",
                }}
              >
                Contractor
              </p>
              <p style={{ margin: 0 }}>{CardDetail?.contractor}</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "5px 5px 10px -2px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: "bold",
                  color: "#aaa",
                  fontSize: ".8em",
                }}
              >
                Distance
              </p>
              <p style={{ margin: 0 }}>{CardDetail?.distance} Km</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "5px 5px 10px -2px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: "bold",
                  color: "#aaa",
                  fontSize: ".8em",
                }}
              >
                SSU ID
              </p>
              <p style={{ margin: 0 }}>{CardDetail.scu_id}</p>
            </div>
            <div className="CardDetail-briefs">
              <div className="Detailicons-show">
                <FaBatteryFull size={25} />
                <p>{CardDetail?.batteryParcent !== null ? CardDetail?.batteryParcent.toFixed(2) : "NA"}%</p>
              </div>
              <div className="Detailicons-show">
                <FaSignal size={25} />
                <p>{CardDetail?.rssi}%</p>
              </div>
              <div className="Detailicons-show">
                <FaPlug size={25} />
                <p>{CardDetail.power === 1 ? "On" : "Off"}</p>
              </div>
              <div className="Detailicons-show">
                <MdSpeed size={25} />
                <p>{CardDetail?.speed}</p>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default DetailCard;
