import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
//@ts-ignore
import { DateRangePicker, RangeWithKey } from "react-date-range";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import format from "date-fns/format";
import { CgCalendarDates } from "react-icons/cg";

export const DateRange2 = (props: any) => {
  const selectionRange: Required<
    Pick<RangeWithKey, "startDate" | "endDate" | "key">
  > = {
    startDate: !!props.startDate ? new Date(props.startDate) : new Date(),
    endDate: !!props.endDate ? new Date(props.endDate) : new Date(),
    key: "selection",
  };
  const [SelectedDates, setSelectedDates] =
    useState<Required<Pick<RangeWithKey, "startDate" | "endDate" | "key">>>(
      selectionRange
    );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleSelect = (e: any) => {
    if (e?.selection?.startDate && e?.selection?.endDate)
      setSelectedDates((state) => {
        return {
          ...state,
          startDate: e.selection.startDate,
          endDate: e.selection.endDate,
        };
      });
    else console.log(e);

    const startingDate = format(e?.selection?.startDate, "yyyy-MM-dd");
    const endingDate = format(e?.selection?.endDate, "yyyy-MM-dd");

    props.setDates(startingDate, endingDate);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        variant="outlined"
        color="primary"
        style={{
          color: "#566",
          display: "flex",
          justifyContent: "flex",
          alignItems: "center",
        }}
        onClick={handleClick}
      >
        <CgCalendarDates
          size={24}
          color="#566"
          style={{ marginBottom: 3, marginRight: 3 }}
        />
        {format(SelectedDates.startDate, "PP") +
          " - " +
          format(SelectedDates.endDate, "PP")}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DateRangePicker ranges={[SelectedDates]} onChange={handleSelect} />
      </Popover>
    </>
  );
};
