import React, { useEffect, useState } from "react";
import {
  HiStatusOnline,
  HiStatusOffline,
  HiOutlineTrash,
  HiOutlineTruck,
  HiArrowRight,
} from "react-icons/hi";
import DashBoardModal from "../DashBoardModal";

const DashBoardMapKpi = (props: any) => {
  const data = props?.data;
  const [show, setShow] = useState(false);
  const onClose = (e: any) => {
    setShow(e);
  };
  const dataModal = props?.donutsData;
  return (
    <>
      <DashBoardModal isShow={show} onClose={onClose} dataModal={dataModal} />
      <div className="grid grid-cols-5 gap-6 pl-1 pr-4">
        <div className="h-36 bg-[#db930e] rounded-md shadow-2xl p-4 2xl:p-4 flex flex-col space-y-1">
          {/* Vehicles Running */}
          <div className="bg-white rounded-md  shadow-md p-1 w-min">
            <HiOutlineTruck size={35} color={"#585858"} />
          </div>
          <div className=" w-min">
            <h1 className="text-white text-4xl font-bold ">
              {data?.total_vehicles}
            </h1>
          </div>
          <div className=" w-min">
            <h1 className="text-white text-lg font-bold truncate">
              Vehicles Running
            </h1>
          </div>
        </div>
        <div className="h-36 bg-[#5ca310] rounded-md shadow-2xl p-4 2xl:p-4 flex flex-col space-y-1">
          {/* Vehicles Online */}
          <div className="bg-white rounded-md  shadow-md p-1 w-min">
            <HiStatusOnline size={35} color={"#585858"} />
          </div>
          <div className=" w-min">
            <h1 className="text-white text-4xl font-bold ">
              {data?.total_active_vehicles}
            </h1>
          </div>
          <div className=" w-min">
            <h1 className="text-white text-lg font-bold truncate">
              Vehicles Online
            </h1>
          </div>
        </div>
        <div className="h-36 bg-[#e10e23] rounded-md shadow-2xl p-4 2xl:p-4 flex flex-col space-y-1">
          {/* Vehicles Offline */}
          <div className="bg-white rounded-md  shadow-md p-1 w-min">
            <HiStatusOffline size={35} color={"#585858"} />
          </div>
          <div className=" w-min">
            <h1 className="text-white text-4xl font-bold ">
              {!!data && data?.total_vehicles - data?.total_active_vehicles}
            </h1>
          </div>
          <div className=" w-min">
            <h1 className="text-white text-lg font-bold truncate">
              Vehicles Offline
            </h1>
          </div>
        </div>
        {/* -----------------------Waste Collected Today------------------------------ */}
        <div
          onClick={() => setShow(!show)}
          className="h-36  bg-[#e3280b] duration-100 rounded-md cursor-pointer shadow-2xl p-4 2xl:p-4 flex flex-col space-y-1 hover:scale-105 transition-all "
        >
          {/* Waste Collected Today */}
          <div className="flex justify-between">
            <div className="bg-white rounded-md   shadow-md p-1 w-min">
              <HiOutlineTrash size={35} color={"#585858"} />
            </div>
            <HiArrowRight size={35} color={"#fff"} />
          </div>

          <div className="w-min items-center">
            <h1 className="text-white text-4xl font-bold truncate items-center">
              {data?.total_waste_collected}{" "}
              <span className="text-white text-lg font-bold">kgs</span>
            </h1>
          </div>
          <div className=" w-min">
            <h1 className="text-white text-lg font-bold truncate">
              Waste Collected
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoardMapKpi;
