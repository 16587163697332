import axios from "axios";
import moment from "moment";

export interface DataPointsType {
  devid: string;
  latitude: number;
  longitude: number;
  createdAt: string;
}

export interface getDataPointsType {
  data: DataPointsType[];
  totalDistance: string;
  status: string;
  total: number;
}

export const getDataPoints = async (
  devid: string,
  start_date: Date | null,
  end_date: any
) => {
  var data = JSON.stringify({
    devid: devid,
    start_time: moment(start_date).format("YY-MM-DD HH:mm:ss"),
    end_time: moment(end_date).format("YY-MM-DD HH:mm:ss"),
  });

  var config: any = {
    method: "post",
    url: `https://monster2.distronix.in:1443/api/new/locationHistory`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const response: any = await axios(config).catch((err) => {
    console.log(err);
  });

  return response.data;
};
