import axios from "axios";

export interface VehiclesReadDataType {
  id: number;
  vehicle_number: string;
  vehicle_image?: any;
  registration_date: any;
  device_id: number;
  vehicle_type: string;
  manufacturer: string;
  created_at: Date;
  updated_at: Date;
  contractor: string;
}

export interface getVehiclesReadDataType {
  status: string;
  total: number;
  data: VehiclesReadDataType[];
}

export const ReadVehicles = async () => {
  var config: any = {
    method: "get",
    url: `https://monster2.distronix.in:1443/api/vehical/getAllVehicals`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response: any = await axios(config).catch((err) => {
    console.log(err);
  });

  return response;
};
