import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Header from "./components/Header";
import WasteCollection from "./pages/reports/WasteCollection";
import DailyDistanceTravelled from "./pages/reports/DailyDistanceTravelled";
import RoadSweeper from "./pages/reports/RoadSweeper";
import WeighBridge from "./pages/reports/WeighBridge";
import WeighBridgeV2 from "./pages/reports/WeighBridgeV2";
import VehicleAlerts from "./pages/reports/VehicleAlerts";
import Bins from "./pages/masterdata/Bins";
import Vehicles from "./pages/masterdata/Vehicles";
import LocationHistory from "./pages/LocationHistory";
import RepetativeOffenders from "./pages/reports/RepetativeOffender";
import Dashboard from "./pages/dashboard/Dashboard";

function App() {
  const isLoggedIn = useSelector((state: any) => state.user.loggedStatus);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
  }, []);
  const dashboard = () => {
    return <div></div>;
  };
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/mapview" element={<Home />} />
        <Route
          path="/reports/premises-waste-collection"
          element={<WasteCollection />}
        />
        <Route
          path="/reports/daily-distance-travelled"
          element={<DailyDistanceTravelled />}
        />
        <Route path="/reports/road-sweeper" element={<RoadSweeper />} />
        <Route
          path="/reports/RepetativeOffender"
          element={<RepetativeOffenders />}
        />
        <Route path="/reports/weighbridge" element={<WeighBridge />} />
        <Route path="/reports/weighbridge-v2" element={<WeighBridgeV2 />} />
        <Route path="/reports/vehicle-alerts" element={<VehicleAlerts />} />
        <Route path="/masterdata/bins" element={<Bins />} />
        <Route path="/masterdata/vehicles" element={<Vehicles />} />
        <Route path="/location-history" element={<LocationHistory />} />
      </Routes>
    </div>
  );
}

export default App;
