import React, { useEffect, useRef, useState } from "react";
import {
  Inject,
  Toolbar,
  ExcelExport,
  Filter,
  Sort,
  FilterSettingsModel,
  SortSettingsModel,
  PdfExport,
  InfiniteScroll,
  PageSettingsModel,
  ExcelExportProperties,
  excelHeaderQueryCellInfo,
  Page,
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  EditSettingsModel,
  ToolbarItems,
  Edit,
  IEditCell,
} from "@syncfusion/ej2-react-grids";
import { Modal } from "@material-ui/core";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import Sidebar from "../../components/Sidebar";
import { FiEdit, FiDelete } from "react-icons/fi";
import { VehicleEdit } from "../../components/VehicleEdit";
import { VehicleCreate } from "../../components/VehicleCreate";

import {
  ReadVehicles,
  VehiclesReadDataType,
} from "../../API/MasterDataCrud/Vehicles/Read";
import moment from "moment";
import VehicleDelete from "../../components/VehicleDelete";
import { useAppSelector } from "../../app/hooks";

const Vehicles = () => {
  const { email } = useAppSelector((s) => s.user);

  // Data Grid
  const grid = useRef<GridComponent | null>(null);
  const toolbarClick = (args: ClickEventArgs) => {
    if (grid.current && args.item.text === "Excel Export") {
      const excelExportProperties: ExcelExportProperties = {
        fileName: "Template Report" + ".xlsx",
        header: {
          headerRows: 8,
          rows: [
            {
              cells: [
                {
                  colSpan: 29,
                  value: "WBPCB",
                  style: {
                    backColor: "#cde6f7",
                    fontColor: "#000000",
                    fontSize: 18,
                    hAlign: "Center",
                    bold: true,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: 29,
                  value: "West Bengal Pollution Control Board",
                  style: {
                    backColor: "#cde6f7",
                    fontColor: "#000000",
                    fontSize: 16,
                    hAlign: "Center",
                    bold: true,
                  },
                },
              ],
            },
            // {
            //   cells: [
            //     {
            //       colSpan: 29,
            //       value: `District: ${selectedDistrict}`,
            //       style: {
            //         fontSize: 12,
            //       },
            //     },
            //   ],
            // },
            // {
            //   cells: [
            //     {
            //       colSpan: 29,
            //       value: `Device: ${DeviceName}`,
            //       style: {
            //         fontSize: 12,
            //       },
            //     },
            //   ],
            // },
            {
              cells: [
                {
                  colSpan: 29,
                  value:
                    "Parameter: PM1, PM25, PM10, AD0 NO2 OPT2, ADC NO2 OPT1, ADC SO2 OPT2, ADC SO2 OPT1, EXTERNAL TEMPERATURE, EXTERNAL HUMIDITY, POWER GOOD, RSSI, BIN ARRAY, M TO F ARRAY, SAMPLE PERIOD, SFR, INTERNAL TEMPERATURE ADC, INTERNAL HUMIDITY ADC, REJECT GLITCH, REJECT LONG TO F, REJECT RATIO, REJECT COUNT OUT OF RANGE, LASER STATUS, FAN FEEDBACK, TAMPER, SOLAR, POWER GOOD",
                  style: {
                    fontSize: 12,
                  },
                },
              ],
            },
            // {
            //   cells: [
            //     {
            //       colSpan: 29,
            //       value: `From: ${moment(StartDate).format(
            //         "DD/MM/YYYY hh:mm:ss"
            //       )} To: ${moment(EndDate).format("DD/MM/YYYY hh:mm:ss")}`,
            //       style: {
            //         fontSize: 12,
            //       },
            //     },
            //   ],
            // },
            // {
            //   cells: [
            //     {
            //       colSpan: 29,
            //       value: `Downloaded on ${moment().format(
            //         "DD-MM-YYYY hh:mm:ss A"
            //       )}`,
            //       style: {
            //         fontSize: 12,
            //       },
            //     },
            //   ],
            // },
            // {
            //   cells: [
            //     {
            //       colSpan: 29,
            //       value: DeviceName,
            //       style: {
            //         backColor: "#204FAC",
            //         fontColor: "#ffffff",
            //         fontSize: 13,
            //         hAlign: "Center",
            //       },
            //     },
            //   ],
            // },
          ],
        },
      };
      grid.current.excelExport(excelExportProperties);
    }
  };

  const excelHeaderQueryCellInfo = (args: ClickEventArgs) => {
    if (
      (grid.current?.excelExportModule as any).sheet.images === null ||
      (grid.current?.excelExportModule as any).sheet.images === undefined
    ) {
      (grid.current?.excelExportModule as any).sheet.images = [];
    }
    const excelImage: any = {
      //   image: wbpcb_base64,
      row: 1,
      column: 1,
      lastRow: 2,
      lastColumn: 2,
    };
    (grid.current?.excelExportModule as any).sheet.images.push(excelImage);
  };

  const filterOptions: FilterSettingsModel = {
    type: "Excel",
  };

  const pageSettings: PageSettingsModel = { pageSize: 150 };

  const toolbar: ToolbarItems[] = ["ExcelExport", "Search"];

  // End DataGrid

  const getReadVehiclesData = async () => {
    setVehiclesData([]);
    const res = await ReadVehicles();
    let result: any = [];
    if (email === "user4@nkda.in") {
      const tempr = res.data.data;
      const filteredData2 = tempr.filter(
        (s: any) => s.contractor === "Modern Nursery"
      );
      result = filteredData2;
    } else {
      result = res.data.data;
    }
    result.map((item: any) => {
      setVehiclesData((d) => [
        ...d,
        {
          ...item,
          registration_date: moment(item.registration_date).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          created_at: moment(item.created_at).format("YYYY-MM-DD HH:mm:ss"),
          updated_at: moment(item.updated_at).format("YYYY-MM-DD HH:mm:ss"),
        },
      ]);
    });
  };

  const [VehiclesData, setVehiclesData] = useState<VehiclesReadDataType[]>([]);
  useEffect(() => {
    getReadVehiclesData();
  }, []);

  const [ToggleEdit, setToggleEdit] = useState(false);
  const [ToggleCreate, setToggleCreate] = useState(false);
  const [ToggleDelete, setToggleDelete] = useState(false);

  const [Ob, setOb] = useState<any>();

  const editTemplate = (args: any) => {
    return (
      <FiEdit
        style={{ cursor: "pointer" }}
        onClick={() => editVehicles(args)}
      />
    );
  };
  const deleteTemplate = (args: any) => {
    return (
      <FiDelete
        style={{ cursor: "pointer" }}
        onClick={() => {
          setToggleDelete(true);
          setOb(args);
        }}
      />
    );
  };

  const editVehicles = (args: any) => {
    setOb(args);
    setToggleEdit(true);
  };

  return (
    <div className="Container">
      <Sidebar />
      <div className="DataGridContainer">
        <h1>Master Data / Vehicles</h1>
        {email !== "user4@nkda.in" && (
          <button className="btn2 mt-3" onClick={() => setToggleCreate(true)}>
            Add New Vehicle
          </button>
        )}

        <Modal
          open={ToggleEdit}
          // onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="flex flex-row justify-around items-center"
        >
          <VehicleEdit
            setToggleModal={setToggleEdit}
            data={Ob}
            Grid={grid}
            getReadVehiclesData={getReadVehiclesData}
          />
        </Modal>
        <Modal
          open={ToggleCreate}
          // onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="flex flex-row justify-around items-center"
        >
          <VehicleCreate
            setToggleModal={setToggleCreate}
            Grid={grid}
            getReadVehiclesData={getReadVehiclesData}
          />
        </Modal>
        <Modal
          open={ToggleDelete}
          // onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="flex flex-row justify-around items-center"
        >
          <VehicleDelete
            setToggleModal={setToggleDelete}
            data={Ob}
            Grid={grid}
            getReadVehiclesData={getReadVehiclesData}
          />
        </Modal>
        <div className="Grid">
          <GridComponent
            toolbarClick={toolbarClick}
            ref={(g) => (grid.current = g)}
            filterSettings={filterOptions}
            dataSource={VehiclesData}
            toolbar={toolbar}
            allowFiltering
            allowTextWrap
            allowSorting
            allowPaging={true}
            pageSettings={pageSettings}
            allowPdfExport
            allowExcelExport
            allowResizing={true}
            excelHeaderQueryCellInfo={excelHeaderQueryCellInfo}
          >
            <ColumnsDirective>
              <ColumnDirective
                width="150"
                field="id"
                headerText="Id"
                isPrimaryKey={true}
              />
              <ColumnDirective
                width="150"
                field="vehicle_number"
                headerText="Vehicle No."
              />
              <ColumnDirective
                width="150"
                field="registration_date"
                headerText="Registration Date"
              />
              <ColumnDirective
                width="150"
                field="device_id"
                headerText="Device ID"
              />
              <ColumnDirective
                width="150"
                field="vehicle_type"
                headerText="Vehicle Type"
                editType="dropdownedit"
              />
              <ColumnDirective
                width="160"
                field="manufacturer"
                headerText="Manufacturer"
              />
              <ColumnDirective
                width="150"
                field="contractor"
                headerText="Contractor"
              />
              <ColumnDirective
                width="150"
                field="created_at"
                headerText="Created At"
                allowEditing={false}
              />
              <ColumnDirective
                width="150"
                field="updated_at"
                headerText="Updated At"
                allowEditing={false}
              />
              {email !== "user4@nkda.in" && (
                <ColumnDirective
                  headerText="Edit"
                  textAlign="Left"
                  width="120"
                  template={editTemplate}
                />
              )}
              {email !== "user4@nkda.in" && (
                <ColumnDirective
                  headerText="Delete"
                  //@ts-ignore
                  textAlign="left"
                  width="120"
                  template={deleteTemplate}
                />
              )}
            </ColumnsDirective>
            <Inject
              services={[
                Toolbar,
                ExcelExport,
                PdfExport,
                Filter,
                Sort,
                Resize,
                InfiniteScroll,
                Page,
              ]}
            />
          </GridComponent>
        </div>
      </div>
    </div>
  );
};

export default Vehicles;
