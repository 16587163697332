import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
//@ts-ignore
import { Calendar, DateRangePicker, RangeWithKey } from "react-date-range";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import format from "date-fns/format";
import { CgCalendarDates } from "react-icons/cg";

import "./CalenderComp.css";
import moment from "moment";

export const DateRangeDonuts = ({ onDateSelect }: any) => {
  const [date, setDate] = useState<any>(new Date());
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onSelectHandler = (item: any) => {
    setAnchorEl(null);
    onDateSelect(item);
    setDate(item);
  };

  return (
    <>
      <Button
        aria-describedby={id}
        variant="outlined"
        color="primary"
        style={{
          color: "#566",
          display: "flex",
          justifyContent: "flex",
          alignItems: "center",
        }}
        onClick={handleClick}
      >
        <CgCalendarDates
          size={24}
          color="#566"
          style={{ marginBottom: 3, marginRight: 3 }}
        />
        {moment(date).format("MMMM Do YYYY")}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Calendar onChange={onSelectHandler} date={date} maxDate={new Date()} />
      </Popover>
    </>
  );
};
