import axios from "axios";

export interface vehicleListType {
  vehicalNumber: string;
  lastActive: string;
  scu_id: string;
  vehicalManufacturer: string;
  batteryParcent: number;
  power: number;
  rssi: number;
  contractor: string;
  distance: number;
  speed: number;
  lat: number;
  long: number;
  vehicle_type: string;
}

export const getVehicleListData = async () => {
  var config: any = {
    method: "get",
    url: "https://monster2.distronix.in:1443/api/new/overview",
    headers: {},
  };

  const response: any = await axios(config).catch((err) => {
    console.log(err);
  });

  return response.data.data;
};
