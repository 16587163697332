import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { GrFormClose } from "react-icons/gr";
import { FaEdit } from "react-icons/fa";
import Select from "react-select";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import moment from "moment";
import { getAllDeviceId } from "../API/getAllDeviceId";
import { CreateVehicle } from "../API/MasterDataCrud/Vehicles/Create";

export const VehicleCreate: React.FC<{
  setToggleModal: React.Dispatch<React.SetStateAction<boolean>>;
  Grid: any;
  getReadVehiclesData: any;
}> = ({ setToggleModal = () => {}, Grid, getReadVehiclesData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const selectStyle = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: "0px",
      borderColor: "#000",
      borderWidth: "2px",
      padding: "2px",
      minWidth: "256px",
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: "0px",
    }),
  };

  const [DevicesOption, setDevicesOption] = useState<any[]>([]);

  const [RegDate, setRegDate] = useState<Date>(new Date(moment().format()));
  const [VehicleType, setVehicleType] = useState<any>();
  const [Vehicle_num, setVehicle_num] = useState<any>();
  const [Manufacturer, setManufacturer] = useState<any>();
  const [Contractor, setContractor] = useState<any>();
  const [DeviceId, setDeviceId] = useState<any>();
  const [MakeClick, setMakeClick] = useState(false);

  useEffect(() => {
    const getDevicesId = async () => {
      const result = await getAllDeviceId();
      result.data.data.map((item: any) => {
        setDevicesOption((prevState: any) => [
          ...prevState.filter((item2: any) => item2.value !== item.id),
          {
            label: item.device_id,
            value: String(item.id),
          },
        ]);
      });
    };
    getDevicesId();
  }, []);

  const handleRegDatePicker = (e: any) => {
    setRegDate(
      new Date(
        moment(e.value, "MM/DD/YYYY hh:mm a").format("DD/MM/YYYY hh:mm a")
      )
    );
  };

  const VehicleTypeOption = [
    { label: "Sweeper", value: "Sweeper" },
    { label: "Truck", value: "Truck" },
    { label: "Bus", value: "Bus" },
    { label: "Car", value: "Car" },
    { label: "Motorcycle", value: "Motorcycle" },
  ];

  useEffect(() => {
    if (
      !!VehicleType &&
      !!Vehicle_num &&
      !!Manufacturer &&
      !!Contractor &&
      !!DeviceId
    ) {
      setMakeClick(true);
    }
  }, [Vehicle_num, DeviceId, VehicleType, Manufacturer, Contractor]);

  const CreateVehicleRow = async () => {
    const result = await CreateVehicle(
      Vehicle_num,
      RegDate,
      DeviceId.value,
      VehicleType.value,
      Manufacturer,
      Contractor
    );
    if (result?.status === 201) {
      enqueueSnackbar("Data Added Sucessfully", { variant: "success" });
      getReadVehiclesData();
      Grid.refresh();
      setToggleModal(false);
    }
  };

  const ShootError = () => {
    enqueueSnackbar("Please Fill All Fields", { variant: "error" });
  };

  return (
    <div className="bg-white w-4/6 absolute">
      <div className="flex flex-row justify-between px-2 py-2 bg-slate-300">
        <p className="font-semibold flex flex-row">
          <span className="pt-1 pr-2">
            <FaEdit />
          </span>{" "}
          Create
        </p>
        <GrFormClose
          size={25}
          className="cursor-pointer"
          onClick={() => setToggleModal(false)}
        />
      </div>
      <div className="mx-5 mt-5 mb-10">
        <div className="flex flex-col justify-center ">
          <div className="flex flex-row justify-between gap-x-10 pb-4">
            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                Vehicle Type
              </label>
              <Select
                styles={selectStyle}
                options={VehicleTypeOption}
                defaultValue={VehicleType}
                value={VehicleType}
                onChange={setVehicleType}
              />
            </div>
            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                Vehicle Number
              </label>
              <input
                type="text"
                className="border-2 border-gray-700 px-3 py-2 "
                value={Vehicle_num}
                onChange={(e) => setVehicle_num(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="flex flex-row justify-between gap-x-10 pb-4">
            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                Registration Date
              </label>
              <DateTimePickerComponent
                id="datetimepicker"
                value={RegDate}
                onChange={(e: any) => handleRegDatePicker(e)}
              />
            </div>

            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                Device Id
              </label>
              <Select
                styles={selectStyle}
                options={DevicesOption}
                defaultValue={DeviceId}
                value={DeviceId}
                onChange={setDeviceId}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between gap-x-10 pb-4">
            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                Manufacturer
              </label>
              <input
                type="text"
                className="border-2 border-gray-700 px-3 py-2 "
                value={Manufacturer}
                onChange={(e) => setManufacturer(e.target.value)}
                required
              />
            </div>

            <div className="flex flex-col flex-1">
              <label className="text-sm pb-2 font-semibold text-gray-500">
                Contractor
              </label>
              <input
                type="text"
                className="border-2 border-gray-700 px-3 py-2 "
                value={Contractor}
                onChange={(e) => setContractor(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-xs text-blue-600 pt-2">
              *All fields are mandatory
            </p>
            <div
              className="mt-2 bg-blue-400 text-white px-3 py-2 w-40 text-center font-semibold cursor-pointer shadow-md hover:shadow-lg"
              onClick={MakeClick ? CreateVehicleRow : ShootError}
            >
              CREATE
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
