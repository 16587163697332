import React, { useRef, useState, useEffect } from "react";
import {
  Inject,
  Toolbar,
  ExcelExport,
  Filter,
  Sort,
  FilterSettingsModel,
  SortSettingsModel,
  PdfExport,
  InfiniteScroll,
  PageSettingsModel,
  ExcelExportProperties,
  excelHeaderQueryCellInfo,
  Page,
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  PdfExportProperties,
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import Sidebar from "../../components/Sidebar";
import DatePicker from "react-date-picker";
import { MultiSelect } from "react-multi-select-component";
import { nkda_base64 } from "../../assets/nkda_base64";
import moment from "moment";
import { getVehicleListData } from "../../API/getVehicleList";
import {
  getRoadSweeperData,
  getRoadSweeperDataType,
} from "../../API/getRoadSweeperData";

const RoadSweeper = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [Vehicles, setVehicles] = useState<any>([]);
  const [VehicleSelect, setVehicleSelect] = useState<any[]>([]);

  const getVehicleList = async () => {
    const result = await getVehicleListData();
    result.map((item: any) => {
      setVehicles((prevState: any) => [
        ...prevState,
        { value: item.scu_id, label: item.vehicalNumber },
      ]);
    });
  };

  useEffect(() => {
    getVehicleList();
  }, []);
  const [vehiclesApiList, setvehiclesApiList] = useState<string[]>([]);
  const [GridData, setGridData] = useState<getRoadSweeperDataType>();
  useEffect(() => {
    if (!!VehicleSelect.length) {
      VehicleSelect.map((item: any) => {
        setvehiclesApiList((prevState: any) => [
          ...prevState.filter((data: any) => item.value !== data),
          item.value,
        ]);
      });
    }
  }, [VehicleSelect]);

  const getRSData = async () => {
    const result = await getRoadSweeperData(
      vehiclesApiList,
      startDate,
      endDate
    );

    const res = result.map((i: any) => {
      const vehile_no = Vehicles.find((j: any) => j.value === i.vehicalNumber);
      return {
        ...i,
        vehicalNumber: vehile_no.label,
      };
    });

    setGridData(res);
  };

  // Data Grid
  const grid = useRef<GridComponent | null>(null);
  const toolbarClick = (args: ClickEventArgs) => {
    if (grid.current && args.item.text === "Excel Export") {
      const excelExportProperties: ExcelExportProperties = {
        fileName:
          `SWM Road Sweeper Report ${moment(startDate).format(
            "DD/MM/YYYY"
          )}-${moment(endDate).format("DD/MM/YYYY")} ` + ".xlsx",
        header: {
          headerRows: 4,
          rows: [
            {
              cells: [
                {
                  colSpan: 6,
                  value: "WBPCB",
                  style: {
                    backColor: "#cde6f7",
                    fontColor: "#000000",
                    fontSize: 18,
                    hAlign: "Center",
                    bold: true,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: 6,
                  value: "West Bengal Pollution Control Board",
                  style: {
                    backColor: "#cde6f7",
                    fontColor: "#000000",
                    fontSize: 16,
                    hAlign: "Center",
                    bold: true,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: 6,
                  value: `From: ${moment(startDate).format(
                    "DD/MM/YYYY"
                  )} To: ${moment(endDate).format("DD/MM/YYYY")}`,
                  style: {
                    fontSize: 12,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: 6,
                  value: `Downloaded on ${moment().format("DD-MM-YYYY")}`,
                  style: {
                    fontSize: 12,
                  },
                },
              ],
            },
          ],
        },
      };
      grid.current.excelExport(excelExportProperties);
    }
    if (grid.current && args.item.text === "PDF Export") {
      const exportProperties: PdfExportProperties = {
        fileName:
          `SWM Road Sweeper Report ${moment(startDate).format(
            "DD/MM/YYYY"
          )}-${moment(endDate).format("DD/MM/YYYY")} ` + ".pdf",
      };
      grid.current.pdfExport(exportProperties);
    }
  };

  const excelHeaderQueryCellInfo = (args: ClickEventArgs) => {
    if (
      (grid.current?.excelExportModule as any).sheet.images === null ||
      (grid.current?.excelExportModule as any).sheet.images === undefined
    ) {
      (grid.current?.excelExportModule as any).sheet.images = [];
    }
    const excelImage: any = {
      image: nkda_base64,
      row: 1,
      column: 1,
      lastRow: 2,
      lastColumn: 1,
    };
    (grid.current?.excelExportModule as any).sheet.images.push(excelImage);
  };

  const filterOptions: FilterSettingsModel = {
    type: "Excel",
  };

  const toolbar = ["ExcelExport", "PdfExport", "Search"];
  const pageSettings: PageSettingsModel = { pageSize: 150 };

  // End DataGrid

  return (
    <div className="Container">
      <Sidebar />
      <div className="DataGridContainer">
        <h1>Reports / Road Sweeper</h1>
        <div className="Dropdowns">
          <div className="labelDrop">
            <p>Vehicles</p>
            <MultiSelect
              className="z-50"
              options={Vehicles}
              hasSelectAll
              value={VehicleSelect}
              onChange={setVehicleSelect}
              labelledBy="Select"
            />
          </div>
          <div className="datePicker">
            <div className="labelDrop">
              <p>Start Date</p>
              <DatePicker
                className="z-50"
                onChange={(date: Date) => setStartDate(date)}
                value={startDate}
              />
            </div>
            <div className="labelDrop">
              <p>End Date</p>
              <DatePicker
                className="z-50"
                onChange={(date: Date) => setEndDate(date)}
                value={endDate}
              />
            </div>
          </div>
          <button className="btn" onClick={() => getRSData()}>
            Submit
          </button>
        </div>
        <div className="Grid">
          <GridComponent
            toolbarClick={toolbarClick}
            ref={(g) => (grid.current = g)}
            filterSettings={filterOptions}
            toolbar={toolbar}
            dataSource={GridData}
            allowFiltering
            allowSorting
            allowPaging={true}
            pageSettings={pageSettings}
            allowPdfExport
            allowExcelExport
            allowResizing={true}
            excelHeaderQueryCellInfo={excelHeaderQueryCellInfo}
          >
            <ColumnsDirective>
              <ColumnDirective width="150" field="sl_no" headerText="S. No." />
              <ColumnDirective width="150" field="date" headerText="Date" />
              <ColumnDirective
                width="150"
                field="vehicalNumber"
                headerText="Vehicle Number"
              />
              <ColumnDirective
                width="150"
                field="numberOfDataPoints"
                headerText="No. of Data Points"
              />
              <ColumnDirective
                width="150"
                field="dead_run"
                headerText="Dead Run Distance (KM)"
              />
              <ColumnDirective
                width="150"
                field="actual_run"
                headerText="Actual Run Distance (KM)"
              />
            </ColumnsDirective>
            <Inject
              services={[
                Toolbar,
                ExcelExport,
                PdfExport,
                Filter,
                Sort,
                Resize,
                InfiniteScroll,
                Page,
              ]}
            />
          </GridComponent>
        </div>
      </div>
    </div>
  );
};

export default RoadSweeper;
