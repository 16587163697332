import Chart from "react-apexcharts";
import React, { useState } from "react";
import { DateRange2 } from "../DateRange/DateRange2";
import moment from "moment";
import DataRangeDrop from "./DateRangeDrop";
import {
  areaChartData,
  graphDataInterface,
} from "../../API/DashboardAPI/DashboardAPIS";
type Props = {};

const DashboardAreaChart = (props: any) => {
  const [dateData, setDateDate] = React.useState<any>([]);
  const [solid, setSolid] = React.useState<number[]>([]);
  const [wet, setWet] = React.useState<number[]>([]);
  const [gen, setGen] = React.useState<number[]>([]);

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(
    moment(moment().subtract(7, "days").calendar()).format("YYYY-MM-DD")
  );
  const setDates = (e: any) => {
    setStartDate(moment(e.endDate).format("YYYY-MM-DD"));
    setEndDate(moment(e.startDate).format("YYYY-MM-DD"));
  };

  const date: string[] = [];
  const dataWet: number[] = [];
  const dataGen: number[] = [];
  const dataSolid: number[] = [];
  const graphDataFn = async () => {
    const grapData: graphDataInterface = await areaChartData({
      startDate,
      endDate,
    });
    if (grapData.status === "success") {
      {
        grapData.data?.map((e) => {
          date.push(moment(e.date).format("MMMM Do YYYY"));
          dataWet.push(parseInt(e.wet_solid_waste));
          dataGen.push(e.general);
          dataSolid.push(e.dry_solid_waste);
        });
        setDateDate(date);
        setSolid(dataSolid);
        setWet(dataWet);
        setGen(dataGen);
      }
    }
  };

  React.useEffect(() => {
    graphDataFn();
  }, [endDate]);

  const option = {
    chart: {
      height: 100,
      type: "area",
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: "smooth",
      width: 2,
      dashArray: 0,
    },
    legend: {
      show: true,
      position: "top",
      fontSize: "16px",
      fontWeight: 600,
    },
    xaxis: {
      tickPlacement: "on",
      type: "categorie",
      categories: dateData,
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: any, index: any) {
          return parseFloat(val) / 1000 + " (Ton)";
        },
      },
      title: {
        text: "Waste collected in Ton",
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "16px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
    },

    tooltip: {},
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        // shadeIntensity: 0.05,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        // inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0.6,
        stops: [0, 50, 100],
        colorStops: [],
      },
    },
  };
  const series = [
    {
      name: "Dry",
      data: solid,
    },
    {
      name: "Wet",
      data: wet,
    },
    {
      name: "General",
      data: gen,
    },
  ];
  return (
    <div className="pl-2">
      <div className="flex justify-end pr-4 pb-2 ">
        {/* <DateRange2 setDates={setDates}/> */}
        <DataRangeDrop setDates={setDates} />
      </div>
      <Chart
        //@ts-ignore
        options={option}
        series={series}
        type="area"
        height={300}
      />
    </div>
  );
};

export default DashboardAreaChart;
