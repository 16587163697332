//@ts-ignore
import { DefinedRange } from "react-date-range";
import React, { useState, SyntheticEvent } from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import moment from "moment";
import "./Datarange.css";

const DataRangeDrop = ({ setDates }: any) => {
  const [state, setState] = useState([
    {
      startDate: new Date(moment().subtract(7, "days").calendar()),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div>
      <Button variant="outlined" onClick={handleClick}>
        {moment(state[0]?.startDate).format("ll")} -
        {moment(state[0]?.endDate).format("ll")}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <DefinedRange
          onChange={(item: any) => {
            setState([item.selection]);
            setDates(item.selection);
            handleClose();
          }}
          //@ts-ignore
          ranges={state}
          staticRanges={[
            {
              label: "Last 7 days",
              range: () => ({
                startDate: new Date(moment().subtract(7, "days").calendar()),
                endDate: new Date(),
              }),
              isSelected() {
                return false;
              },
            },
            {
              label: "Last 30 days",
              range: () => ({
                startDate: new Date(moment().subtract(30, "days").calendar()),
                endDate: new Date(),
              }),
              isSelected() {
                return false;
              },
            },
            {
              label: "Last 60 days",
              range: () => ({
                startDate: new Date(moment().subtract(60, "days").calendar()),
                endDate: new Date(),
              }),
              isSelected() {
                return false;
              },
            },
            {
              label: "Last 90 days",
              range: () => ({
                startDate: new Date(moment().subtract(90, "days").calendar()),
                endDate: new Date(),
              }),
              isSelected() {
                return false;
              },
            },
            {
              label: "Last year",
              range: () => ({
                startDate: new Date(moment().subtract(365, "days").calendar()),
                endDate: new Date(),
              }),
              isSelected() {
                return false;
              },
            },
          ]}
        />
      </Popover>
    </div>
  );
};

export default DataRangeDrop;
