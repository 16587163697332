import React from "react";
import { getVTSKPIData } from "../../API/getVTSKPIData";
import DashBoardMapKpi from "../../components/Card/DashBoardMapKpi";
import ShtCards from "../../components/Card/ShtCards";
import DashboardAreaChart from "../../components/Charts/DashboardAreaChart";
import DashboardDonut from "../../components/Charts/DashboardDonut";
import Sidebar from "../../components/Sidebar";
import {
  donutsDataAPIDateAdded,
  graphDataInterface,
} from "../../API/DashboardAPI/DashboardAPIS";
import moment from "moment";

type Props = {};

const Dashboard = (props: Props) => {
  const [VTSKPIData, setVTSKPIData] = React.useState<any>();
  const [donutData, setDonutData] = React.useState<any>();

  const getVTSKPI = async () => {
    const result = await getVTSKPIData();
    setVTSKPIData({
      total_vehicles: result.total_vehicles,
      total_active_vehicles: result.total_active_vehicles,
      total_waste_collected: result.total_waste_collected,
      total_dry_waste_collected: result.total_dry_waste_collected,
      total_wet_waste_collected: result.total_wet_waste_collected,
    });
  };
  const today_date = moment().format("YYYY-MM-DD");
  const donutsDataFn = async () => {
    const data: graphDataInterface = await donutsDataAPIDateAdded(today_date);
    if (data.status === "success") {
      setDonutData(data?.data[0]);
      // console.log(data?.data[0]);
    }
  };
  React.useEffect(() => {
    donutsDataFn();
  }, []);

  React.useEffect(() => {
    getVTSKPI();
  }, []);

  const donutsData = [
    parseInt(donutData?.dry_solid_waste),
    parseInt(donutData?.wet_solid_waste),
    parseInt(donutData?.general),
  ];

  return (
    <>
      <div className="ContainerDashboard">
        <Sidebar />
        <div className="Homepage">
          <div className="space-y-2">
            {/* Top KIP for sht */}
            <h1 className="pb-4 pt-2 text-[#1d1d1d] select-none px-2 text-4xl font-bold">
              Today's Overview
            </h1>
            <ShtCards />

            {/* Map KIPS */}
            <div className="py-3">
              <DashBoardMapKpi data={VTSKPIData} donutsData={donutsData} />
            </div>

            {/*  Charts */}
            <div className="grid grid-cols-2 2xl:grid-cols-3 gap-4 pl-2">
              {/* Line charts */}
              <div className="shadow-lg bg-[#fff] rounded-2xl items-center justify-center 2xl:col-span-2  py-3">
                <DashboardAreaChart />
                {/* <DashboardAreaChart /> //dateData={dateData} */}
              </div>

              {/* Donuts charts */}
              <div className="shadow-lg bg-[#fff] rounded-2xl items-center justify-center py-3 mr-4 max-w-xl">
                <DashboardDonut data={donutsData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
