import React, { useEffect } from "react";
import { FaTruck, FaBatteryFull, FaSignal, FaPlug } from "react-icons/fa";
import { Collapse } from "@mui/material";
import { vehicleListType } from "../API/getVehicleList";
import moment from "moment";

export interface VehicleListProps {
  vehicleList: vehicleListType;
  setCardDetails: React.Dispatch<
    React.SetStateAction<vehicleListType | undefined>
  >;
  CardDetails: vehicleListType | undefined;
  Selected: string | undefined;
  popClose: any;
  popOpen: any;
}

const ListCards: React.FC<VehicleListProps> = ({
  vehicleList,
  setCardDetails,
  CardDetails,
  Selected,
  popClose,
  popOpen,
}) => {
  const color = {
    active: "#555",
    notActive: "#333333",
  };

  return (
    <div
      style={{
        backgroundColor:
          Selected === vehicleList.scu_id ? color.active : color.notActive,
      }}
      className={`ListCards`}
      onClick={() => {
        setCardDetails(vehicleList);
        popClose();
      }}
    >
      <div className="ListCardsHeader justify-between" id={Selected}>
        <div className="flex flex-row">
          <div
            style={{
              backgroundColor: "#fff",
              padding: "5px 5px 2px 5px",
              borderRadius: 5,
            }}
          >
            <FaTruck />
          </div>{" "}
          <p
            style={{
              margin: "0px",
              fontWeight: "bold",
              padding: "2px 0 0 10px",
            }}
          >
            {vehicleList.vehicalNumber}
          </p>
        </div>
        <p style={{ margin: "6px 0 0 5px", fontSize: ".7em" }}>
          {moment(
            moment(vehicleList.lastActive).format("YYYY-MM-DD HH:mm:ss")
          ).fromNow()}
        </p>
      </div>
      <div>
        <p style={{ margin: "5px 5px 5px -2px" }}>{vehicleList.scu_id}</p>
      </div>
      <div>
        <p style={{ margin: "5px 5px 5px -2px" }}>
          {vehicleList.vehicalManufacturer}
        </p>
      </div>
      <div className="ListCard-briefs">
        <div className="icons-show">
          <FaBatteryFull />
          <p>
            {vehicleList.batteryParcent !== null
              ? vehicleList.batteryParcent.toFixed(2)
              : "NA"}
            %
          </p>
        </div>
        <div className="icons-show">
          <FaSignal />
          <p>{vehicleList.rssi}%</p>
        </div>
        <div className="icons-show">
          <FaPlug />
          <p>{vehicleList.power === 1 ? "On" : "Off"}</p>
        </div>
      </div>
    </div>
  );
};

export default ListCards;
