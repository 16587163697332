import React, { useRef, useState } from "react";
import {
  Inject,
  Toolbar,
  ExcelExport,
  Filter,
  Sort,
  FilterSettingsModel,
  SortSettingsModel,
  PdfExport,
  InfiniteScroll,
  PageSettingsModel,
  ExcelExportProperties,
  Page,
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  PdfExportProperties,
  SelectionSettingsModel,
  Aggregate,
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import Sidebar from "../../components/Sidebar";
import DatePicker from "react-date-picker";
import moment from "moment";
import { nkda_base64 } from "../../assets/nkda_base64";
import { getRepeatedOffendersData } from "../../API/getRepeatedOffendersData";
import { Modal } from "@mui/material";
import { GrFormClose } from "react-icons/gr";

const RepetativeOffenders = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [GridData, setGridData] = useState<any>([]);
  const [GridData2, setGridData2] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [VehicleNumber, setVehicleNumber] = useState([]);

  const getWBData = async () => {
    const result = await getRepeatedOffendersData(startDate, endDate);
    console.log(result);

    setGridData(result);
  };

  const handleClickCell = (e: any, m: any) => {
    setGridData2(m);
    setVehicleNumber(e);
    setOpen(true);
  };

  const editTemplate = (args: any) => {
    console.log(args);

    return (
      <p
        style={{
          cursor: "pointer",
          color: "blue",
          textDecoration: "underline",
        }}
        onClick={() =>
          handleClickCell(args?.vehicle_no, args?.repetative_offend)
        }
      >
        {args[args.column.field]}
      </p>
    );
  };

  // Data Grid
  const grid = useRef<GridComponent | null>(null);
  const toolbarClick = (args: ClickEventArgs) => {
    if (grid.current && args.item.text === "Excel Export") {
      const excelExportProperties: ExcelExportProperties = {
        fileName:
          `SWM Weighbridge Report ${moment(startDate).format(
            "DD/MM/YYYY"
          )}-${moment(endDate).format("DD/MM/YYYY")} ` + ".xlsx",
        header: {
          headerRows: 4,
          rows: [
            {
              cells: [
                {
                  colSpan: 11,
                  value: "WBPCB",
                  style: {
                    backColor: "#cde6f7",
                    fontColor: "#000000",
                    fontSize: 18,
                    hAlign: "Center",
                    bold: true,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: 11,
                  value: "West Bengal Pollution Control Board",
                  style: {
                    backColor: "#cde6f7",
                    fontColor: "#000000",
                    fontSize: 16,
                    hAlign: "Center",
                    bold: true,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: 11,
                  value: `From: ${moment(startDate).format(
                    "DD/MM/YYYY"
                  )} To: ${moment(endDate).format("DD/MM/YYYY")}`,
                  style: {
                    fontSize: 12,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: 11,
                  value: `Downloaded on ${moment().format("DD-MM-YYYY")}`,
                  style: {
                    fontSize: 12,
                  },
                },
              ],
            },
          ],
        },
      };
      grid.current.excelExport(excelExportProperties);
    }
    if (grid.current && args.item.text === "PDF Export") {
      const exportProperties: PdfExportProperties = {
        pageOrientation: "Landscape",
        pageSize: "A2",
        fileName:
          `SWM Repeated Offender Report ${moment(startDate).format(
            "DD/MM/YYYY"
          )}-${moment(endDate).format("DD/MM/YYYY")} ` + ".pdf",
      };
      grid.current.pdfExport(exportProperties);
    }
  };

  const excelHeaderQueryCellInfo = (args: ClickEventArgs) => {
    if (
      (grid.current?.excelExportModule as any).sheet.images === null ||
      (grid.current?.excelExportModule as any).sheet.images === undefined
    ) {
      (grid.current?.excelExportModule as any).sheet.images = [];
    }
    const excelImage: any = {
      image: nkda_base64,
      row: 1,
      column: 1,
      lastRow: 2,
      lastColumn: 1,
    };
    (grid.current?.excelExportModule as any).sheet.images.push(excelImage);
  };

  const filterOptions: FilterSettingsModel = {
    type: "Excel",
  };

  const toolbar = ["ExcelExport", "PdfExport", "Search"];
  const pageSettings: PageSettingsModel = { pageSize: 150 };

  // End DataGrid

  const cellSelected = (args: any) => {
    //@ts-ignore
    grid.current.filterByColumn(
      //@ts-ignore
      grid.current.getColumnByIndex(args.cellIndex.cellIndex).field,
      "equal",
      args.currentCell.innerHTML
    );
  };

  const settings: SelectionSettingsModel = {
    mode: "Cell",
  };

  return (
    <div className="Container">
      <Sidebar />

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="flex flex-row justify-around items-center"
      >
        <div className="bg-white w-4/6 absolute">
          <div className="flex flex-row justify-between px-2 py-2 bg-slate-300 mb-5">
            <p className="font-semibold flex flex-row">
              <span className="pt-1 pr-2"></span> Repeated Offender Report for{" "}
              the Vehicle Number {VehicleNumber}
            </p>
            <GrFormClose
              size={25}
              className="cursor-pointer"
              onClick={() => setOpen(false)}
            />
          </div>
          <div className="Grid">
            <GridComponent
              toolbarClick={toolbarClick}
              ref={(g) => (grid.current = g)}
              filterSettings={filterOptions}
              toolbar={toolbar}
              dataSource={GridData2}
              allowFiltering
              allowTextWrap
              allowSorting
              allowPaging={true}
              pageSettings={pageSettings}
              allowPdfExport
              allowExcelExport
              allowResizing={true}
              excelHeaderQueryCellInfo={excelHeaderQueryCellInfo}
              cellSelected={cellSelected}
              selectionSettings={settings}
            >
              <ColumnsDirective>
                <ColumnDirective
                  width="150"
                  field="date_two"
                  headerText="Collection Date"
                  allowFiltering={false}
                  valueAccessor={(field: any, data: any, column) => {
                    return moment(data[field], "YYYY-MM-DD HH:mm:ss").format(
                      "DD/MM/YYYY"
                    );
                  }}
                />
                <ColumnDirective
                  width="150"
                  field="collection_time_in_minutes"
                  headerText="Collection Time(in minutes)"
                  allowFiltering={false}
                />
              </ColumnsDirective>

              <Inject
                services={[
                  Toolbar,
                  ExcelExport,
                  PdfExport,
                  Filter,
                  Sort,
                  Resize,
                  InfiniteScroll,
                  Page,
                  Aggregate,
                ]}
              />
            </GridComponent>
          </div>
        </div>
      </Modal>

      <div className="DataGridContainer overflow-x-hidden items-stretch flex-1 ">
        <h1>Reports / Repeated Offender</h1>
        <div className="Dropdowns">
          <div className="datePicker">
            <div className="labelDrop">
              <p>Start Date</p>
              <DatePicker
                className="z-50"
                onChange={(date: Date) => setStartDate(date)}
                value={startDate}
              />
            </div>
            <div className="labelDrop">
              <p>End Date</p>
              <DatePicker
                className="z-50"
                onChange={(date: Date) => setEndDate(date)}
                value={endDate}
              />
            </div>
          </div>
          <button className="btn" onClick={getWBData}>
            Submit
          </button>
        </div>
        <div className="Grid">
          <GridComponent
            toolbarClick={toolbarClick}
            ref={(g) => (grid.current = g)}
            filterSettings={filterOptions}
            toolbar={toolbar}
            dataSource={GridData}
            allowFiltering
            allowTextWrap
            allowSorting
            allowPaging={true}
            pageSettings={pageSettings}
            allowPdfExport
            allowExcelExport
            allowResizing={true}
            excelHeaderQueryCellInfo={excelHeaderQueryCellInfo}
            cellSelected={cellSelected}
            selectionSettings={settings}
          >
            <ColumnsDirective>
              <ColumnDirective
                width="150"
                field="vehicle_no"
                headerText="Vehicle No."
              />
              <ColumnDirective
                width="150"
                field="transporter_name"
                headerText="Agency Name"
              />
              <ColumnDirective
                width="150"
                field="repetative_offend_count"
                headerText="Count Of Offends"
                template={editTemplate}
                allowFiltering={false}
              />
            </ColumnsDirective>

            <Inject
              services={[
                Toolbar,
                ExcelExport,
                PdfExport,
                Filter,
                Sort,
                Resize,
                InfiniteScroll,
                Page,
                Aggregate,
              ]}
            />
          </GridComponent>
        </div>
      </div>
    </div>
  );
};

export default RepetativeOffenders;
